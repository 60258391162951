<template>
  <b-card-code>
    <b-row>
      <b-col class="text-right">
        <b-button
          variant="primary"
          @click="isActivated = true"
        >
          <FeatherIcon icon="PlusIcon" />
          <span class="text-nowrap">&nbsp;Adicionar</span>
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h5>Empresa Selecionada:</h5>
        <h3 class="text-primary">
          {{ company.name }}
        </h3>
      </b-col>
    </b-row>

    <i2-side-bar
      :is-active.sync="isActivated"
      window-title="Unidade"
    >
      <unity
        :unity="current"
        :company-uuid="company.uuid"
        @onSave="onSave()"
      />
    </i2-side-bar>

    <Grid
      :fields="fields"
      :items="items"
      edit-button-label="edit"
      new-button-label="new"
    >

      <template #cell(is_offline)="data">
        <b-badge
          :variant="!data.item.data.isOffline ? 'success' : 'danger'"
        >
          {{ !data.item.data.isOffline ? 'Sim' : 'Não' }}
        </b-badge>
      </template>
      <template #cell(code)="data">
        <b>{{ data.item.data.code }}</b>
      </template>

      <template
        #cell(action)="data"
        style="width: 10%"
      >
        <b-dropdown
          variant="link"
          no-caret
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item @click="setCurrent(data.item)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Editar</span>
          </b-dropdown-item>

          <b-dropdown-item @click="showWells(data.item)">
            <feather-icon icon="StopCircleIcon" />
            <span class="align-middle ml-50">Poços</span>
          </b-dropdown-item>
          <b-dropdown-item @click="remove(data.item)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Excluir</span>
          </b-dropdown-item>
        </b-dropdown>
        <div
          v-if="false"
          class="px-4 text-center"
        >
          <b-button
            variant="outline-primary"
            class="btn-icon"
            @click="setCurrent(data.item)"
          >
            <feather-icon
              icon="EditIcon"
            />
          </b-button>
          &nbsp;
          <b-button
            variant="outline-primary"
            class="btn-icon"
            @click="showWells(data.item)"
          >
            <feather-icon
              icon="StopCircleIcon"
            />
          </b-button>
          &nbsp;
          <b-button
            variant="outline-danger"
            class="btn-icon"
            @click="remove(data.item)"
          >
            <feather-icon
              icon="TrashIcon"
            />
          </b-button>
        </div>
      </template>
    </Grid>

    <!-- MODAL PARA ALTERACAO DE POCOS -->
    <b-modal
      id="modal-xl"
      ref="wells_modal"
      ok-only
      scrollable
      ok-title="Fechar"
      centered
      size="xl"
      :title="'Poços em '+current.name"
    >
      <validation-observer
        ref="refFormObserver"
      >
        <form @submit.prevent="onSubmit">
          <b-row class="align-items-center">
            <b-col
              sm="3"
              md="3"
            >
              <i2-form-input
                v-model="currentPit.name"
                rules="required"
                label="Nome"
                name="name"
              />
            </b-col>
            <b-col
              sm="3"
              md="3"
            >
              <i2-form-input
                v-model="currentPit.description"
                rules="required"
                label="Descrição"
                name="description"
              />
            </b-col>
            <b-col
              sm="6"
              md="6"
            >
              <i2-form-select
                v-model="currentPit.device_uuid"
                name="type"
                rules="required"
                label="Dispositivo"
                :options="devices"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="4"
              md="4"
            >
              <i2-form-input
                v-model="currentPit.grant_limit"
                rules="required|min_value:1"
                type="number"
                label="Limite de consumo diário outorgado (L)"
                name="grant_limit"
              />
            </b-col>
            <b-col
              sm="4"
              md="4"
            >
              <i2-form-input
                v-model="currentPit.deep"
                rules="required|min_value:1"
                type="number"
                label="Profundidade de Instalação da Sonda (m)"
                name="deep"
              />
            </b-col>
            <b-col
              sm="4"
              md="4"
            >
              <i2-form-input
                v-model="currentPit.pump_run_time"
                rules="required|min_value:1"
                type="number"
                label="Limite de Funcionamento da Bomba (H)"
                name="pump_run_time"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
              style="text-align: right"
            >
              <b-button
                variant="outline-primary"
                class="btn-icon mr-2"
                @click="validatePit"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                Salvar
              </b-button>
              <b-button
                variant="outline-info"
                class="btn-icon"
                @click="clearPit"
              >
                <feather-icon
                  icon="FileIcon"
                  class="mr-50"
                />
                Limpar
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
      <Grid
        :fields="fieldsWells"
        :items="current.wells"
        edit-button-label="edit"
        new-button-label="new"
      >
        <template #cell(device)="data">
          {{ data.item.device.identifier }}
        </template>

        <template
          #cell(action)="data"
          style="width: 10%"
        >
          <div class="px-4 text-center">
            <b-button
              variant="flat-primary rounded-circle"
              class="btn-icon"
              @click="setCurrentPit(data.item)"
            >
              <feather-icon
                icon="EditIcon"
              />
            </b-button>
            &nbsp;
            <b-button
              variant="flat-danger rounded-circle"
              class="btn-icon"
              @click="removePit(data.item)"
            >
              <feather-icon
                icon="TrashIcon"
              />
            </b-button>
          </div>
        </template>
      </Grid>
    </b-modal>

  </b-card-code>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Grid from '@/components/i2/grid/Grid.vue'
import I2SideBar from '@/components/i2/container/I2SideBar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Unity from '@/views/company/unity/Unity.vue'
import I2FormInput from '@/components/i2/forms/elements/I2FormInput.vue'
import I2FormSelect from '@/components/i2/forms/elements/I2FormSelect.vue'

export default {
  components: {
    I2FormSelect,
    ValidationObserver,
    I2FormInput,
    Unity,
    I2SideBar,
    Grid,
    BCardCode,
  },
  data() {
    return {
      isShowWells: false,
      company: {
        name: '',
        uuid: '',
      },
      wellsUnity: [],
      fields: [
        {
          key: 'name',
          label: 'Unidade',
          class: 'w-40',
          stickyColumn: true,
        },
        {
          key: 'is_offline',
          label: 'Opera Online',
          class: 'w-10',
        },
        {
          key: 'code',
          label: 'Código',
          class: 'w-40',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-20',
        },
      ],
      fieldsWells: [
        {
          key: 'name',
          label: 'Nome',
          class: 'w-10',
        },
        {
          key: 'device',
          label: 'Dispositivo',
          class: 'w-20',
        },
        {
          key: 'description',
          label: 'Descrição',
          class: 'w-60',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-10',
        },
      ],
      items: [],
      current: {
        code: '',
        name: '',
        uuid: '',
        device_uuid: null,
        grant_limit: 0,
        deep: 0,
        pump_run_time: 0,
        data: {},
      },
      originalDevices: [],
      currentPit: {},
      devices: [],
      isActivated: false,
    }
  },
  watch: {
    isActivated(val) {
      if (!val) {
        this.current = {
          code: '',
          name: '',
          uuid: '',
          grant_limit: 0,
          deep: 0,
          pump_run_time: 0,
          data: {},
        }
        if (this.$refs.refFormObserver) {
          this.$refs.refFormObserver.reset()
        }
      }
    },
  },
  mounted() {
    this.getCompany()
    this.getUnitys()
  },
  methods: {
    async getCompany() {
      if (this.$route.params.uuid) {
        this.company = await this.$http.get(`company/${this.$route.params.uuid}`)
      }
    },
    async getUnitys() {
      if (this.$route.params.uuid) {
        this.items = await this.$http.get(`company/${this.$route.params.uuid}/unitys?include=wells`)
      }
    },
    async getDevices() {
      const devices = await this.$http.get('device')
      this.devices = []
      this.devices = devices.map(device => ({
        value: device.uuid,
        text: device.identifier,
        disabled: device.is_in_use,
        originalState: device.is_in_use,
      }))
    },
    setCurrent(unity) {
      this.current = { ...unity }
      this.isActivated = true
    },
    setCurrentPit(pit) {
      pit.device_uuid = ''
      this.devices.map(device => {
        device.disabled = device.originalState
        return device
      })
      if (pit.device) {
        pit.device_uuid = pit.device.uuid
        const pitDevice = this.devices.find(device => device.value === pit.device.uuid)
        pitDevice.disabled = false
      }
      this.clearPit()
      this.currentPit = { ...pit }
    },
    onSave() {
      this.current = {}
      this.isActivated = false
      this.getUnitys()
    },
    remove(unity) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme a exclusão da unidade.', {
          title: 'Confirma a exclusão?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            const response = await this.$http.delete(`unity/${unity.uuid}`)
            if (response.error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.error_message,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `A unidade ${unity.name} foi removida com sucesso.`,
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              await this.getUnitys()
            }
          }
          return true
        })
    },
    removePit(pit) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme a exclusão do poço.', {
          title: 'Confirma a exclusão?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            const response = await this.$http.delete(`pit/${pit.uuid}`)
            if (response.error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.error_message,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `O poço ${pit.name} foi removido com sucesso.`,
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              await this.reloadWells()
            }
          }
          return true
        })
    },
    showWells(data) {
      this.current = { ...data }
      this.getDevices()
      this.$refs.wells_modal.show()
    },
    clearPit() {
      this.currentPit = {}
      this.$refs.refFormObserver.reset()
    },
    validatePit() {
      this.$refs.refFormObserver.validate()
        .then(async success => {
          if (success) {
            if (this.currentPit.uuid !== undefined && this.currentPit.device_uuid) {
              this.$bvModal
                .msgBoxConfirm('Identificamos a alteração do dispositivo.', {
                  title: 'Tem certeza que deseja alterar o dispositivo vinculado ao poço?',
                  size: 'sm',
                  okVariant: 'primary',
                  okTitle: 'Sim',
                  cancelTitle: 'Não',
                  cancelVariant: 'outline-secondary',
                  hideHeaderClose: false,
                  centered: true,
                })
                .then(async accept => {
                  this.savePit(accept, false)
                })
            } else {
              this.savePit(true, this.currentPit.uuid === undefined)
            }
          }
        })
    },
    async savePit(accept, isNew) {
      if (!accept) {
        return false
      }
      this.currentPit.unity_uuid = this.current.uuid
      let response = null
      if (isNew) {
        response = await this.$http.post('pit', this.currentPit)
      } else {
        response = await this.$http.put(`pit/${this.currentPit.uuid}`, this.currentPit)
      }
      if (response.error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error_message,
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `O poço ${this.currentPit.name} foi salvo com sucesso.`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.reloadWells()
        this.$refs.refFormObserver.reset()
      }
      return true
    },
    async reloadWells() {
      await this.getUnitys()
      await this.getDevices()
      this.current.wells = []
      this.current = this.items.find(unity => unity.uuid === this.current.uuid)
      this.clearPit()
    },
  },
}
</script>
<style>
th.w-1 {
  width: 70%;
}
th.w-40 {
  width: 45%;
}
th.w-2 {
  width: 20%;
}
th.w-3 {
  width: 10%;
}
th.w-10 {
  width: 20%;
  white-space: nowrap;
}
.btn-icon > mr-2{
  margin-bottom: 20px;
}
</style>
