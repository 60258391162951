<template>
  <div>

    <b-form-group
      :label="label"
      :label-for="name"
    >
      <validation-provider
        #default="{ errors }"
        :name="label"
        :vid="label"
        :rules="rules"
      >
        <b-form-select
          v-model="inputValue"
          :name="name"
          :options="options"
          :readonly="readonly"
          :disabled="disabled"
        />
        <small class="text-danger">
          &nbsp;{{ errors[0] }}
        </small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>

import { BFormSelect, BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'I2FormSelect',
  components: {
    ValidationProvider,
    BFormSelect,
    BFormGroup,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Object],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    rules: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: '',
      selected: null,
    }
  },
  watch: {
    value(data) {
      this.inputValue = data
    },
    inputValue(val) {
      this.$emit('input', val)
    },
  },
}
</script>

<style scoped>

</style>
