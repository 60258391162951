<template>

  <!-- BODY -->
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <!-- Form -->
    <b-form
      class="p-2"
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
    >
      <b-row>
        <b-col
          sm="12"
          md="12"
        >
          <b-form-checkbox
            v-model="model.data.isOffline"
            :checked="model.data.isOffline"
            class="custom-control-primary"
            name="is-offline"
            switch
          >
            Esta unidade irá operar offline.
          </b-form-checkbox>

        </b-col>
      </b-row>
      <i2-divider />
      <b-row>
        <b-col
          sm="12"
          md="12"
        >
          <i2-form-input
            v-model="model.code"
            rules=""
            label="Código"
            name="code"
            :disabled="true"
            :readonly="true"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
        >
          <i2-form-input
            v-model="model.name"
            rules="required"
            label="Nome"
            placeholder="informe o nome da unidade"
            name="name"
          />

          <i2-form-input
            v-model="model.data.cnpj"
            label="CNPJ"
            mask="###.###.###/####-##"
            placeholder="Ex: 000.000.000/0000-00"
            name="cnpj"
          />

          <i2-form-input
            v-model="model.data.phone"
            label="Telefone"
            mask="(##) # ####-####"
            placeholder="Ex: (00) 0 0000-0000"
            name="phone"
          />
        </b-col>
      </b-row>

      <b-row>
        <p>Dados da Unidade</p>
      </b-row>
      <Address
        :address="model.data.address"
        @input="address"
      />
      <b-row />

      <b-row>
        <b-col
          sm="12"
          md="12"
        >
          <!-- Form Actions -->
          <div class="d-flex mt-2 text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >

              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              Salvar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import { BFormCheckbox } from 'bootstrap-vue'
import { alphaNum, email, required } from '@validations'
import Ripple from 'vue-ripple-directive'
import I2FormInput from '@/components/i2/forms/elements/I2FormInput.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Address from '@/components/i2/content/Address.vue'
import I2Divider from '@/components/i2/content/i2Divider.vue'

export default {
  components: {
    I2Divider,
    Address,
    I2FormInput,
    BFormCheckbox,
    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    unity: {
      type: Object,
      required: true,
    },
    companyUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      active: false,
      model: {
        name: '',
        data: {
          code: '', phone: '', cnpj: '', address: {}, isOffline: false,
        },
        uuid: null,
      },
      blankModel: {
        name: '',
        data: {
          code: '', phone: '', cnpj: '', address: {}, isOffline: false,
        },
        uuid: null,
      },
    }
  },
  watch: {
    isActive(data) {
      this.active = data
      if (!data) {
        this.model = { ...this.blankModel }
        this.$refs.refFormObserver.reset()
      }
    },
    unity(unity) {
      if (!unity.data.isOffline) {
        unity.data.isOffline = ''
      }

      if (!unity.data.phone) {
        unity.data.phone = ''
      }
      if (!unity.data.cnpj) {
        unity.data.cnpj = ''
      }
      if (!unity.data.address) {
        unity.data.address = {}
      }
      this.model = unity
      if (unity && unity.data.code !== undefined) {
        this.model.code = unity.data.code
      }
      if (!unity.uuid) {
        this.model = { ...this.blankModel }
        this.$refs.refFormObserver.reset()
      }
    },
  },
  methods: {
    onSubmit() {
      this.model.company_uuid = this.$route.params.uuid

      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          let response = {}
          if (this.model.uuid) {
            response = await this.$http.put(`unity/${this.model.uuid}`, this.model)
          } else {
            response = await this.$http.post('unity', this.model)
          }
          if (response.error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.error_message,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
            return
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Unidade salva com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$emit('onSave', response)
          this.model = { ...this.blankModel }
          this.$refs.refFormObserver.reset()
        }
      })
    },
    address(value) {
      this.model.data.address = value.address
    },
  },
}
</script>
